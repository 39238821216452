import { useDialog } from '@react-aria/dialog';
import { useOverlay } from '@react-aria/overlays';
import React, { createRef } from 'react';

import { Portal, VizTooltipContainer } from '@grafana/ui';
import { TooltipProps } from '../types';
import { CloseButton } from './CloseButton';
import { DataHoverView } from './DataHoverView';

export const Tooltip = ({ ttip, onClose, isOpen, data, gateway, rowIndex, tooltipTitle }: TooltipProps) => {
  const ref = createRef<HTMLElement>();
  const { overlayProps } = useOverlay({ onClose, isDismissable: true, isOpen }, ref);
  const { dialogProps } = useDialog({}, ref);

  return (
    <>
      { ttip && (
        <Portal>
          <VizTooltipContainer
            position={ { x: ttip.pageX, y: ttip.pageY } } offset={ { x: 10, y: 10 } }
            allowPointerEvents
          >
            <section
              ref={ ref }
              { ...overlayProps }
              { ...dialogProps }
            >
              { isOpen && <CloseButton style={ { zIndex: 1 } } onClick={ onClose }/> }
              <DataHoverView
                data={ data }
                gateway={ gateway }
                rowIndex={ rowIndex }
                header={ tooltipTitle }
              />
            </section>
          </VizTooltipContainer>
        </Portal>
      )}
    </>
  );
};
