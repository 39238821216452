import { get, isPlainObject, set } from 'lodash'
import {
  getActiveThreshold,
  MappingType,
  SpecialValueMatch,
  SpecialValueOptions,
  stringToJsRegex,
  ThresholdsConfig,
  ThresholdsMode,
  ValueMapping,
  ValueMappingResult
} from '@grafana/data';
import { Gateway2 } from '../types';

function arrayComparator (a: any, b: any, order: []) {
  let result = 0
  for (let i = 0; i < Math.min(Math.max(a.length, b.length), order.length); i++) {
    if (a[i] !== b[i]) {
      if (a[i] === null || a[i] === undefined) {
        result = 1
      } else if (b[i] === null || b[i] === undefined) {
        result = -1
      } else {
        result = (a[i] < b[i] ? -1 : 1) * order[i] === -1 ? -1 : 1
      }
      break
    }
  }
  return result
}

export function sort (
  objects: any[],
  sortby: string | string[],
  getter: (object: any, f: string) => any,
  getterOrder: (field: string) => any,
  index = 'id'
) {
  sortby = sortby === undefined ? index : sortby
  sortby = Array.isArray(sortby) ? sortby : [sortby]
  const order = sortby.reduce((a, f) => a.concat(getterOrder(f)), [])
  // @ts-ignore
  return objects
    .map(object => {
      return {
        object,
        // @ts-ignore
        sort: sortby.reduce((a: string | any[], f: string) => a.concat(getter(object, f)), [])
      }
    })
    // @ts-ignore
    .sort((a, b) => arrayComparator(a.sort, b.sort, order))
    .map(o => o.object)
}

export function group (objects: any[], groupby: string[], indexId = 'id') {
  groupby = groupby === undefined ? [] : groupby
  groupby = Array.isArray(groupby) ? groupby : [groupby]
  groupby = groupby.map(f => f.replace(/^-/, ''))
  const groupValues = objects.map(i => ({
    id: i[indexId],
    group: groupby.map((f) => {
      let v = i[f].value
      if (isPlainObject(v) || (Array.isArray(v) && v.length === 0)) {
        v = [null]
      } else if (!Array.isArray(v)) {
        v = [v]
      }
      return v
    })
  }))

  if (groupby.length === 0) {
    return [{
      keys: [],
      group: groupValues.map(i => i.id)
    }]
  }

  const hierarchy = {}

  function addToHierarhy (prefix: any, levels: any, element: any) {
    const newLevels = levels.slice()
    const curLevel = newLevels.shift()

    curLevel.forEach((l: any) => {
      const newPrefix = prefix.slice()
      newPrefix.push(l)

      if (newLevels.length === 0) {
        const value = get(hierarchy, newPrefix, [])
        // @ts-ignore
        value.push(element)
        set(hierarchy, newPrefix, value)
      } else {
        addToHierarhy(newPrefix, newLevels, element)
      }
    })
  }

  // @ts-ignore
  groupValues.forEach(({ id, group, sort }) => {
    addToHierarhy([], group, { id, sort })
  })

  function flatten (result: any, names: any, keys: any, mapped: any) {
    const newNames = names.slice()
    const name = newNames.shift()

    Object.keys(mapped).forEach((key) => {
      const newKeys = keys.slice()
      newKeys.push({ name, key })

      const element = mapped[key]

      if (newNames.length === 0) {
        result.push({
          keys: newKeys,
          group: element
            .map((e: { id: any }) => e.id)
            .filter((v: any, i: any, self: string | any[]) => self.indexOf(v) === i)
        })
      } else {
        flatten(result, newNames, newKeys, element)
      }
    })
  }

  const result: never[] = []
  flatten(result, groupby, [], hierarchy)
  return result
}

export function getValueThresholdsResult(thresholds: ThresholdsConfig, element: Gateway2, columnName: string) {
  // @ts-ignore
  const elementValue = element[columnName];

  if (thresholds.mode === ThresholdsMode.Percentage) {
    let percent = 0;

    if (elementValue.value !== -Infinity) {
      percent = (elementValue.value - elementValue.min!) / elementValue.delta;

      if (Number.isNaN(percent)) {
        percent = 0;
      }
    }

    return getActiveThreshold(percent * 100, thresholds?.steps).color
  }

  return getActiveThreshold(elementValue.value, thresholds.steps).color;
}

export function getValueMappingResult(valueMappings: ValueMapping[], value: any): ValueMappingResult | null {
  for (const vm of valueMappings) {
    switch (vm.type) {
      case MappingType.ValueToText:
        if (value == null) {
          continue;
        }

        const result = vm.options[value];
        if (result) {
          return result;
        }

        break;

      case MappingType.RangeToText:
        if (value == null) {
          continue;
        }

        const valueAsNumber = parseFloat(value as string);
        if (isNaN(valueAsNumber)) {
          continue;
        }

        const isNumFrom = !isNaN(vm.options.from!);
        if (isNumFrom && valueAsNumber < vm.options.from!) {
          continue;
        }

        const isNumTo = !isNaN(vm.options.to!);
        if (isNumTo && valueAsNumber > vm.options.to!) {
          continue;
        }

        return vm.options.result;

      case MappingType.RegexToText:
        if (value == null) {
          continue;
        }

        if (typeof value !== 'string') {
          continue;
        }

        const regex = stringToJsRegex(vm.options.pattern);
        if (value.match(regex)) {
          const res = { ...vm.options.result };

          if (res.text != null) {
            res.text = value.replace(regex, vm.options.result.text || '');
          }

          return res;
        }

      case MappingType.SpecialValue:
        switch ((vm.options as SpecialValueOptions).match) {
          case SpecialValueMatch.Null: {
            if (value == null) {
              return vm.options.result;
            }
            break;
          }
          case SpecialValueMatch.NaN: {
            if (isNaN(value as any)) {
              return vm.options.result;
            }
            break;
          }
          case SpecialValueMatch.NullAndNaN: {
            if (isNaN(value as any) || value == null) {
              return vm.options.result;
            }
            break;
          }
          case SpecialValueMatch.True: {
            if (value === true || value === 'true') {
              return vm.options.result;
            }
            break;
          }
          case SpecialValueMatch.False: {
            if (value === false || value === 'false') {
              return vm.options.result;
            }
            break;
          }
          case SpecialValueMatch.Empty: {
            if (value === '') {
              return vm.options.result;
            }
            break;
          }
        }
    }
  }
  return null;
}

export default {
  sort,
  group,
  getValueMappingResult
}
