import { PanelPlugin } from '@grafana/data';
import { SimplePanelProps } from './types';
import { SimplePanel } from './components/SimplePanel';
import { commonOptionsBuilder } from '@grafana/ui';
import { SortGroupEditor } from './components/SortGroup';

// @ts-ignore
export const plugin = new PanelPlugin<SimplePanelProps>(SimplePanel)
  .useFieldConfig({
    useCustomConfig: (builder) => {
      commonOptionsBuilder.addHideFrom(builder);
    },
  })
  .setPanelOptions((builder, context) => {
    const { fields } = context.data[0] || { fields: [] };
    const fieldsOptions = fields.map(({ name }) => ({ label: name, value: name }))

    return builder
      .addBooleanSwitch({
        category: ['Configuration'],
        name: 'Show number of elements',
        path: 'numberOfElements',
        defaultValue: false,
      })
      .addSelect({
        category: ['Configuration'],
        path: 'statusColumnName',
        name: 'Status field',
        description: '',
        settings: {
          options: fieldsOptions
        },
        defaultValue: ''
      })
      .addMultiSelect({
        category: ['Configuration'],
        name: 'Group by',
        path: 'groupBy',
        settings: {
          options: fieldsOptions,
        }
      })
      .addCustomEditor({
        category: ['Configuration'],
        id: 'sortGroup',
        path: 'sortGroup',
        name: 'Sort group type',
        description: '',
        editor: SortGroupEditor,
        defaultValue: { sortGroupType: '', sortGroupDirection: 'asc' },
      })
      .addCustomEditor({
        category: ['Configuration'],
        id: 'sortingWithinGroup',
        path: 'sortingWithinGroup',
        name: 'Sorting within a group',
        description: '',
        editor: SortGroupEditor,
        defaultValue: { sortGroupType: '', sortGroupDirection: 'asc' },
      })
      .addNumberInput({
        category: ['Configuration'],
        path: 'maxElementInGroup',
        name: 'Column size (elements)',
        description: 'The condition works if there is more than one group',
        settings: {
          min: 0
        },
        defaultValue: 5,
      })
      .addNumberInput({
        category: ['Configuration'],
        path: 'widthNumberOfElements',
        name: 'Group width depending on the number of elements',
        // description: 'The condition works if there is more than one group',
        settings: {
          min: 0
        },
        defaultValue: 50,
      })
      .addNumberInput({
        category: ['Configuration'],
        path: 'elementSize',
        name: 'Element Size (px)',
        description: '',
        settings: {
          // min: 40,
        },
        defaultValue: 40,
      })
      .addSelect({
        category: ['Configuration'],
        path: 'elementTextField',
        name: 'Text in element',
        description: '',
        settings: {
          options: fieldsOptions,
          isClearable: true,
        }
      })
      .addNumberInput({
        category: ['Configuration'],
        path: 'elementTextSize',
        name: 'Text size in element (px)',
        description: '',
        defaultValue: 8,
      })
      .addTextInput({
        category: ['Configuration'],
        path: 'tooltipTitle',
        name: 'Tooltip title',
        description: '',
        defaultValue: '',
      })
  });
