import React, { useMemo, useState } from 'react';
import {
  formattedValueToString,
  dataFrameToJSON,
  ValueMapping,
  ThresholdsConfig,
  getMinMaxAndDelta
} from '@grafana/data';
import { Gateway2, GroupType, SimplePanelProps, TooltipState } from '../types';
import { useStyles2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { Tooltip } from './Tooltip';
import { Element } from './element';
import { orderBy } from 'lodash';
import data from './utils'

export const SimplePanel: React.FC<SimplePanelProps> = ({
  options,
  data: PanelData,
  width,
  height
}) => {
  const styles = useStyles2(getStyles);
  const [tooltipState, setTooltipState] = useState<TooltipState>({ ttipOpen: false, ttip: undefined });
  const [rowIndex, setRowIndex] = useState<number>();

  const groupBy = options.groupBy || undefined;

  const {
    thresholds,
    mappings,
    gateways,
    gatewaysMapping
  } = useMemo(
    () => {
      const jsonData = dataFrameToJSON(PanelData.series[0]);

      let thresholds: ThresholdsConfig | undefined;
      let mappings: { [p: string]: ValueMapping[] | undefined } = {};
      let gateways: Gateway2[] = [];
      let gatewaysMapping: { [key: string]: Gateway2 } = {};
      for (let i = 0; i < PanelData.series[0].length; i++) {
        let obj = { id: i } as Gateway2;
        PanelData.series[0].fields.forEach((field, fieldIndex) => {
          thresholds = field.config.thresholds;
          mappings = {
            ...mappings,
            [field.name]: field.config.mappings
          };
          const value = jsonData.data!.values[fieldIndex][i];
          obj = {
            ...obj,
            [field.name]: {
              value,
              // @ts-ignore
              valueString: formattedValueToString(field.display(value)),
              ...(getMinMaxAndDelta(field))
            },
          };
        })
        gateways = [...gateways, obj];
        gatewaysMapping = { ...gatewaysMapping, [obj.id]: obj };
      }
      return {
        thresholds,
        mappings,
        gateways,
        gatewaysMapping
      };
    },
    [PanelData]
  );

  const groups = () => {
    if (options.sortingWithinGroup?.sortGroupType) {
      const isAsc = (!options.sortingWithinGroup?.sortGroupDirection || options.sortingWithinGroup?.sortGroupDirection === 'asc');
      const sortedGateway = isAsc
        ? orderBy(gateways, `${options.sortingWithinGroup?.sortGroupType}.value`, ['asc'])
        : orderBy(gateways, `${ options.sortingWithinGroup?.sortGroupType }.value`, ['desc']);
      return data.group(
        sortedGateway,
        groupBy
      );
    }
    return data.group(
      gateways,
      groupBy
    );
  };

  const sortBySize = (a: GroupType, b: GroupType) => {
    if (options.sortGroup?.sortGroupDirection === 'desc') {
      return b.group.length - a.group.length;
    }
    return a.group.length - b.group.length;
  };

  const sortByName = (a: GroupType, b: GroupType) => {
    if (options.sortGroup?.sortGroupDirection === 'desc') {
      return b.keys[0].key.localeCompare(a.keys[0].key);
    }
    return a.keys[0].key.localeCompare(b.keys[0].key);
  };

  const sortedGroups = () => {
    if (!groupBy?.length) {
      return groups();
    }

    const allGroups = data.sort(
      groups(),
      groupBy,
      (obj: { keys: any[]; }, f: string) => obj.keys.find(k => k.name === f.replace(/^-/, '')).key,
      (f: string) => f.startsWith('-') ? -1 : 1
    );

    if (!options.sortGroup?.sortGroupType) {
      return allGroups;
    }

    if (options.sortGroup.sortGroupType === 'bySize') {
      return allGroups.sort(sortBySize)
    }

    if (options.sortGroup.sortGroupType === 'byGroupName') {
      return allGroups.sort(sortByName)
    }

    return data.sort(
      groups(),
      groupBy,
      (obj: { keys: any[]; }, f: string) => obj.keys.find(k => k.name === f.replace(/^-/, '')).key,
      (f: string) => f.startsWith('-') ? -1 : 1
    )
  };

  const key_name = (key: { key: string; name: any; }) => {
    if (key.key === 'null') {
      return 'unknown'
    }
    return key.key;
  }

  const group_name = (g: { keys: any[]; }) => {
    if (g.keys.length === 0) {
      return 'All'
    }
    return g.keys.map(key_name).join(', ')
  };

  const calcGroupStyle = (g: { group: any[]; }) => {
    const gap = 2;
    const widthNumberOfElements = options.widthNumberOfElements ? Math.floor(g.group.length / options.widthNumberOfElements) : 1;
    if (!widthNumberOfElements) {
      return (options.maxElementInGroup * options.elementSize) + ((options.maxElementInGroup) * gap);
    }
    return ((options.maxElementInGroup * options.elementSize) + ((options.maxElementInGroup) * gap)) * widthNumberOfElements;
  };

  const tooltipPopupClosed = () => {
    setRowIndex(undefined);
    setTooltipState({ ttipOpen: false, ttip: undefined });
  };

  return (
    <>
      <div className={cx(styles.wrapper, css`gap: ${options.elementSize}px; width: ${width}px; height: ${height}px;`)}>
        { sortedGroups().map((g: any, i: React.Key | null | undefined) =>
          <div
            key={ i }
            className={ cx(styles.group, css`flex: ${ options.maxElementInGroup ? `0 1 ${calcGroupStyle(g)}px` : '1 1 320px' }`) }
          >
            <div className="name flex">
              <span>{ group_name(g) }</span>&nbsp;
              { options.numberOfElements && (<span>({ g.group.length })</span>) }
            </div>
            <div className={ cx(styles.elements, css`grid-template-columns: repeat(auto-fit, ${ options.elementSize}px)`) }>
              {/*// @ts-ignore*/}
              { g.group.map((id, index) =>
                <Element
                  id={id}
                  key={index}
                  isActiveGateway={rowIndex === gatewaysMapping[id].id}
                  gateway={gatewaysMapping[id]}
                  statusColumnName={options.statusColumnName}
                  elementSize={options.elementSize}
                  elementTextField={options.elementTextField}
                  elementTextSize={options.elementTextSize}
                  mappings={mappings}
                  thresholds={thresholds}
                  showModal={setTooltipState}
                  setRowIndex={setRowIndex}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Tooltip
        ttip={ tooltipState.ttip }
        isOpen={ tooltipState.ttipOpen }
        onClose={ tooltipPopupClosed }
        data={PanelData.series[0]}
        gateway={ rowIndex ? gatewaysMapping[rowIndex] : undefined}
        rowIndex={ rowIndex }
        tooltipTitle={ options.tooltipTitle }
      />
    </>
  );
};

const getStyles = () => {
  return {
    wrapper: css`
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: 20px;
      padding: 8px;
      overflow: auto;
    `,
    group: css`
      flex: 1 1 320px;
    `,
    elements: css`
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(auto-fit, 40px);
    `
  };
};
