import React from 'react';
import { StandardEditorProps, SelectableValue, GrafanaTheme2 } from '@grafana/data';
import {
  Field,
  FieldSet,
  Select,
  useStyles2,
} from '@grafana/ui';
import { css } from '@emotion/css';
import { SortGroupProps } from '../types';

export const SortGroupEditor = ({
  value,
  onChange,
  context,
  item,
}: StandardEditorProps<SortGroupProps>) => {
  const styles = useStyles2(getStyles);

  const { fields } = context.data[0] || { fields: [] };
  const fieldsOptions = fields.map(({ name }) => ({ label: name, value: name }))

  const typeOptions = [
    { label: 'by size', value: 'bySize' },
    { label: 'by group name', value: 'byGroupName' },
  ];

  const directionOptions = [
    { label: 'ASC', value: 'asc' },
    { label: 'DESC', value: 'desc' },
  ];

  const setType = (option: SelectableValue) => {
    onChange({
      ...value,
      sortGroupType: option?.value || ''
    })
  };

  const setDirection = (option: SelectableValue) => {
    onChange({
      ...value,
      sortGroupDirection: option?.value || ''
    })
  };

  return (
    <>
      <FieldSet className={css(styles.row)}>
        <Field>
          <Select
            isClearable
            options={ item.id === 'sortGroup' ? typeOptions : fieldsOptions }
            value={ value.sortGroupType }
            onChange={ setType }
          />
        </Field>
        <Field>
          <Select
            isClearable
            options={ directionOptions }
            value={ value.sortGroupDirection }
            onChange={ setDirection }
          />
        </Field>
      </FieldSet>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  row: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `,
});
