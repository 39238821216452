import React, { memo } from 'react';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { ElementProps } from '../types';
import { getValueMappingResult, getValueThresholdsResult } from './utils';
import { createTheme } from '@grafana/data';

// eslint-disable-next-line react/display-name
export const Element: React.FC<ElementProps> = memo(({
  id,
  isActiveGateway,
  statusColumnName,
  elementSize,
  elementTextField,
  elementTextSize,
  gateway,
  mappings,
  thresholds,
  showModal,
  setRowIndex
}) => {
  const styles = useStyles2(getStyles);

  const getColor = () => {
    // @ts-ignore
    if (!gateway || !gateway[statusColumnName]) {
      return '#808080';
    }

    const theme = createTheme();
    // @ts-ignore
    const baseColor = getValueMappingResult(mappings[statusColumnName]!, gateway[statusColumnName]!.value)?.color || '';
    // @ts-ignore
    const thresholdColor = (thresholds?.steps.length && gateway[statusColumnName]?.value
      ? theme.visualization.getColorByName(getValueThresholdsResult(thresholds, gateway, statusColumnName))
      : false);
    return baseColor && theme.visualization.getColorByName(baseColor) || thresholdColor || '#808080';
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    onElementClick(event);
    setRowIndex(id);
  };

  const onElementClick = (event: React.MouseEvent<HTMLElement>) => {
    const { clientX, clientY } = event;
    showModal({ ttipOpen: true, ttip: { pageX: clientX, pageY: clientY } });
  };

  const getElementText = () => {
    if (!elementTextField) {
      return '';
    }
    // @ts-ignore
    return gateway[elementTextField]?.valueString || gateway[elementTextField]?.value;
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        css`background: ${getColor()}; width: ${elementSize}px; height: ${ elementSize }px`,
        isActiveGateway && styles.active
      )}
      onClick={onClick}
    >
      { elementTextField &&
        (<span className={ cx(styles.text, css`font-size: ${elementTextSize}px`) }> { getElementText() } </span>)
      }
    </div>
  );
});

const getStyles = () => {
  return {
    wrapper: css`
      position: relative;
      overflow: auto;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.5);
        border: 1px solid white;
        z-index: 1;
      }
    `,
    active: css`
      transform: scale(1.5);
      border: 1px solid white;
      z-index: 2;
    `,
    grid: css`
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: currentColor;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      box-sizing: border-box;
      padding: 8px;
      transition: transform .3s ease;
      box-shadow: white 0 0 0 2px;
    `,
    status: css`
      position: absolute;
      color: white;
      font-size: 16px;
      right: 0;
      top: 0;
    `,
    text: css`
      font-weight: bold;
      font-size: 8px;
      line-height: initial;
      text-align: center;
    `,
  };
};
